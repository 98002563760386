export enum Experiments {
  PrivateMemberIndicator = 'specs.membersArea.PrivateMemberIndicator',
  UiTpaImage = 'specs.profileCardOOI.imgMigration',
  PopoverAndTooltipMigration = 'specs.profileCardOOI.popoverTooltipMigration',
  WorkingReportMemberFeature = 'specs.membersArea.membersListReportMember',
  UseWarmupDataInProfile = 'specs.membersArea.UseWarmupDataInProfile',
  CoverWowImage = 'specs.profileCardOOI.coverWowImage',
  AvatarWowImage = 'specs.profileCardOOI.avatarWowImage',
  SettingsTextTab = 'specs.profileCardOOI.settingsTextTab',
  MobileSettingsDesignTab = 'specs.profileCardOOI.designTabMobile',
  DesktopStylesOnMobile = 'specs.profileCardOOI.desktopColorsOnMobile',
}
